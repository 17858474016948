import Swiper from './vendor/swiper';

const swiperServices = new Swiper('.js-slider--services', {
    slidesPerView: 1.15,
    spaceBetween: 24,
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 24,
        },
    },
});

const swiperQuotes = new Swiper('.js-slider--quote', {
    slidesPerView: 1,
    spaceBetween: 24,
    autoplay: {
        delay: 3000,
    },
});

(function () {
    'use strict';

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia('(min-width:48em)');

    // keep track of swiper instances to destroy later
    let mySwiper;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const breakpointChecker = function () {
        // if larger viewport and multi-row layout needed
        if (breakpoint.matches === true) {
            // clean up old instances and inline styles when available
            if (mySwiper !== undefined) mySwiper.destroy(true, true);

            // or/and do nothing
            return;

            // else if a small viewport and single column layout needed
        } else if (breakpoint.matches === false) {
            // fire small viewport version of swiper
            return enableSwiper();
        }
    };

    const enableSwiper = function () {
        mySwiper = new Swiper('.js-slider--servicesFilter', {
            slidesPerView: 1.75,
            spaceBetween: 8,
        });
    };

    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);

    // kickstart
    breakpointChecker();
})(); /* IIFE end */
