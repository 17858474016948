import $ from 'jquery';
// import './vendor/yt-api';

// Grab data attributes from video-wrapper

// Prepend link to Youtube video if data attr is yes

function loadPlayer() {
    if (typeof YT == 'undefined' || typeof YT.Player == 'undefined') {
        // 2. This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = function () {
            onYouTubeIframeAPIReady();
        };
    } else {
        onYouTubeIframeAPIReady();
    }
}

// 2. This code loads the IFrame Player API code asynchronously.
// var tag = document.createElement('script');
// tag.src = 'https://www.youtube.com/iframe_api';
// var firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player) after the API code downloads.

var player;

function onYouTubeIframeAPIReady() {
    var videoID = $('.video-wrapper').data('video-id');

    var videoEnd = $('.video-wrapper').data('video-end');

    player = new YT.Player('player', {
        videoId: videoID,
        playerVars: {
            autoplay: 1,
            autohide: 1,
            end: videoEnd,
            loop: 1,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
            controls: 0,
            disablekb: 1,
            enablejsapi: 0,
            iv_load_policy: 3,
            origin: 'http://dentalfabrique.test',
        },
        events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
        },
    });
}

// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
    var videoStart = $('.video-wrapper').data('video-start');
    vidRescale();
    event.target.mute();
    event.target.seekTo(videoStart);
}

// 5. The API calls this function when the player's state changes.

function onPlayerStateChange(e) {
    var videoStart = $('.video-wrapper').data('video-start');
    if (e.data === 1) {
        $('#player').addClass('active');
    } else if (e.data === 0) {
        player.seekTo(videoStart);
    }
}

// This function scales the video to window size and uses additional values to push video beyong window size

function vidRescale() {
    var videoWidthAdd = $('.video-wrapper').data('video-width-add');
    var videoHeightAdd = $('.video-wrapper').data('video-height-add');
    var w = $(window).width() + videoWidthAdd,
        h = $(window).height() + videoHeightAdd;
    if (w / h > 16 / 9) {
        player.setSize(w, (w / 16) * 9);
        $('.tv .screen').css({ left: '0px' });
    } else {
        player.setSize((h / 9) * 16, h);
        $('.tv .screen').css({ left: -($('.tv .screen').outerWidth() - w) / 2 });
    }
}

// Reloads the video on load and resize

$(window).on('resize', function () {
    if ($('.video-wrapper')) {
        vidRescale();
    }
});

$(document).ready(function () {
    if ($('.video-wrapper')) {
        loadPlayer();
    }
});
