import $ from 'jquery';

$('.js-searchServices').on('keyup', function (e) {
    e.preventDefault();

    $('.search-form__icon').addClass('remove');

    if ($('#keyword').val().length >= 3) {
        $.ajax({
            url: $('.search-form').attr('action'),
            type: 'post',
            data: {
                action: 'data_fetch',
                keyword: $('#keyword').val(),
            },
            success: function (data) {
                $('.c-pricingList').html();
                $('.c-pricingList').html(data);
            },
        });
    }
});
